import React from "react"
import { Link } from "gatsby"


const DatenschutzContent = () => {
  return (
    <section className="terms-of-service-area ptb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="terms-of-service-content">

              <h2>Datenschutzerklärung</h2>
              <div>
                <h3>Allgemeines</h3>
                <p>Der Datenschutz und die Privatsphäre der Nutzer unserer Internetseiten sind uns ein besonderes
                  Anliegen. Wir verpflichten uns daher, Ihre personenbezogenen Daten zu schützen und nur im Einklang mit
                  der Datenschutz-Grundverordnung (DS-GVO) sowie den nationalen Datenschutzvorschriften zu erheben, zu
                  verarbeiten und zu nutzen. In der nachfolgenden Datenschutzerklärung wird erläutert, welche Ihrer
                  personenbezogenen Daten auf unseren Internetseiten erhoben werden und wie diese Daten verwendet
                  werden. Unsere Datenschutzerklärung wird entsprechend den rechtlichen und technischen Anforderungen
                  regelmäßig aktualisiert. Bitte beachten Sie daher die jeweils aktuelle Fassung unserer
                  Datenschutzerklärung.
                </p>
                <p>Die nachfolgenden Datenschutzbestimmungen gelten ausschließlich für die Internetseiten auf der
                  Website: https://www.logentis.de
                </p>
                <br />
                <h3>I. Name und Anschrift des Verantwortlichen</h3>
                Der Verantwortliche im Sinne der
                Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze sowie sonstiger
                datenschutzrechtlicher Bestimmungen ist die
                <br />
                <br />
                <p>LOGENTIS GmbH<br />
                  Franz-Lenz-Str. 4<br />
                  49084 Osnabrück <br />
                  Deutschland<br />
                  &nbsp;<br />
                  Tel.: +49 541 580587 0<br />
                  Email: <a href="mailto:info@logentis.de"> info@logentis.de</a><br />
                  Webseite: <a href="https://www.logentis.de" target="_blank"
                               rel="noreferrer">logentis.de</a>
                </p>
                <br />
                <h3>II. Name und Anschrift des Datenschutzbeauftragten</h3>
                <p>
                  Der Datenschutzbeauftragte des Verantwortlichen ist:<br />
                  Herr Stefan Liebert<br />
                  Liebert IT-Solutions<br />
                  Drosteallee 18<br />
                  46354 Südlohn<br />
                  Deutschland<br />
                  Tel.: +49 2862 580 123
                </p>
                <p>
                  E-Mail: <a href={"mailto:info@liebert-it.de"}>info@liebert-it.de</a><br />
                  Webseite: <a href="https://www.liebert-it.de" target="_blank" rel="noreferrer">www.liebert-it.de</a>
                </p>
                <br />
                <h3>III. Allgemeines zur Datenverarbeitung</h3>
                <h4>1. Umfang der Verarbeitung personenbezogener Daten</h4>
                <p>Ihre personenbezogenen Daten werden grundsätzlich nur verarbeitet, soweit dies zur Bereitstellung
                  einer funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die
                  Verarbeitung erfolgt regelmäßig nur nach Ihrer Einwilligung, es sei denn eine vorherige Einholung
                  einer Einwilligung ist aus tatsächlichen Gründen nicht möglich und die Verarbeitung der Daten ist
                  durch gesetzliche Vorschriften gestattet.
                  <br />
                  <br />
                </p>
                <h4>2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h4>
                <p>Als Rechtsgrundlagen für die Verarbeitung personenbezogener Daten dienen uns die in Art. 6 Abs. 1
                  DS-GVO normierten Tatbestände wie folgt:</p>
                <p>a) Art. 6 Abs. 1 lit. a DS-GVO, sofern wir für Verarbeitungsvorgänge personenbezogener Daten eine
                  Einwilligung der betroffenen Person einholen.</p>
                <p>b) Art. 6 Abs. 1 lit. b DS-GVO, sofern die Verarbeitung von personenbezogenen Daten zur Erfüllung
                  eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist. Dies gilt auch
                  für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.</p>
                <p>c) Art. 6 Abs. 1 lit. c DS-GVO, sofern eine Verarbeitung personenbezogener Daten zur Erfüllung
                  einer rechtlichen Verpflichtung, der unser Unternehmen unterliegt, erforderlich ist.</p>
                <p>d) Art. 6 Abs. 1 lit. d DS-GVO, sofern lebenswichtige Interessen der betroffenen Person oder einer
                  anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen.</p>
                <p>e) Art. 6 Abs. 1 lit. f DS-GVO, sofern die Verarbeitung zur Wahrung eines berechtigten Interesses
                  unseres Unternehmens oder eines Dritten erforderlich ist und dies die Interessen, Grundrechte und
                  Grundfreiheiten des Betroffenen überwiegt.</p>
                <br />
                <h4>3. Datenlöschung und Speicherdauer </h4>
                <p>Sobald der Zweck der Speicherung entfällt, werden Ihre personenbezogenen Daten gelöscht oder
                  gesperrt. Sieht der europäische oder nationale Gesetzgeber in unionsrechtlichen Verordnungen,
                  Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, eine Speicherung über
                  den Zweck hinaus vor, so kann diese bis zum Ablauf der durch die genannten Normen vorgeschriebenen
                  Speicherfrist erfolgen. Im Anschluss erfolgt auch dann eine Sperrung oder Löschung der Daten, es sei
                  denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder
                  eine Vertragserfüllung besteht.</p>
                <br />
                <h3>IV. Bereitstellung der Website und Erstellung von Logfiles</h3>
                <h4>1. Beschreibung und Umfang der Datenverarbeitung </h4>
                <p>Unser System erfasst bei jedem Aufruf unserer Internetseiten automatisiert Daten und Informationen
                  vom Computersystem des aufrufenden Rechners.</p>
                <p>Hierbei werden folgende Daten erhoben:</p>
                <p>a) Die Nutzer-IP-Adresse</p>
                <p>b) Datum und Uhrzeit des Zugriffs</p>
                <p>c) Ihren Internet-Provider</p>
                <p>d) Informationen über den Zugriffsbrowsertyp und die verwendete Version</p>
                <p>e) Ihr Betriebssystem</p>
                <p>f) Websites, von denen Ihr System auf unsere Internetseite gelangt</p>
                <p>g) Websites, die von Ihrem System über unsere Website aufgerufen werden</p>
                <p>Mit Ausnahme Ihrer IP-Adresse oder anderen Daten, die die Zuordnung der Daten zu Ihnen ermöglichen,
                  werden diese Daten außerdem in den Logfiles unseres Systems gespeichert. Hierbei findet eine
                  Speicherung dieser Daten zusammen mit Ihren anderen personenbezogenen Daten nicht statt.</p>
                <br />
                <h4>2. Rechtsgrundlage für die Datenverarbeitung </h4>
                <p>Für die vorübergehende Speicherung der Daten stellt Art. 6 Abs. 1 lit. f DS-GVO die notwendige
                  Rechtsgrundlage dar.</p>
                <br />
                <h4>3. Zweck der Datenverarbeitung</h4>
                <p>Die Bereitstellung der Website an Ihren Rechner benötigt die vorübergehende Speicherung der
                  IP-Adresse durch das System. Hierfür muss Ihre IP-Adresse für die Dauer der Sitzung gespeichert
                  bleiben.</p>
                <p>Ebenso stellen diese Zwecke unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs.
                  1 lit. f DS-GVO dar.</p>
                <br />
                <h4>4. Dauer der Speicherung</h4>
                <p>Sofern die Datenerhebung zur Erreichung des Zwecks nicht mehr notwendig ist, werden die Daten
                  gelöscht. Bei der Datenerfassung zur Bereitstellung der Website geschieht dies zum jeweiligen
                  Sitzungsende</p>
                <br />
                <h4>5. Widerspruchs- und Beseitigungsmöglichkeit</h4>
                <p>Es besteht für Sie keine Möglichkeit zum Widerspruch, da die Datenerfassung und –speicherung in
                  Logfiles für Bereitstellung und Betrieb der Website zwingend erforderlich sind.</p>
                <br />
                <h3>V. Verwendung von Cookies</h3>
                <h4>1. Beschreibung und Umfang der Datenverarbeitung </h4>
                <p>Unsere Webseite verwendet so genannte „Cookies“. Hierbei handelt es sich um Textdateien, die im
                  Internetbrowser bzw. vom Internetbrowser auf Ihrem Computersystem gespeichert werden. Ein Cookie
                  kann auf Ihrem Betriebssystem gespeichert werden, sobald dieser eine Website aufruft. Dieser Cookie
                  enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim
                  erneuten Aufrufen der Website ermöglicht.</p>
                <p>Der Einsatz von Cookies erfolgt zur nutzerfreundlichen Gestaltung unserer Website. Denn einige
                  Teile unserer Website benötigen eine Identifikation des verwendeten Browsers auch nach einem
                  Seitenwechsel.</p>
                <p>Dabei werden in den Cookies die folgenden Daten gespeichert und übermittelt:</p>
                <p>a) Log-In-Informationen</p>
                <p>Darüber hinaus setzen wir auf unserer Website Cookies zur Analyse Ihres Surfverhaltens ein.</p>
                <br />
                <p>Hierbei können folgende Daten übermittelt werden:</p>
                <p>a) Häufigkeit von Seitenaufrufen</p>
                <p>b) Inanspruchnahme von Website-Funktionen</p>
                <p>c) eingegebene Suchbegriffe</p>
                <p>Sie werden beim Aufruf unserer Website über die Verwendung von Cookies zu Analysezwecken
                  informiert. Hierbei wird auch Ihre Einwilligung eingeholt, die in diesem Zusammenhang verwendeten
                  personenbezogenen Daten verarbeiten zu dürfen. Dabei erfolgt auch der Hinweis auf diese
                  Datenschutzerklärung.</p>
                <br />
                <h4>2. Speicherung von Cookies verhindern </h4>
                <p>Sie können je nach verwendetem Browser einstellen, dass eine Speicherung von Cookies nur nach Ihrer
                  Zustimmung erfolgt. Wenn Sie nur die von uns verwendeten Cookies, nicht aber die Cookies etwaiger
                  Dienstleister und Partner akzeptieren wollen, können Sie die Einstellung in Ihrem Browser "Cookies
                  von Drittanbietern blockieren" wählen. In der Regel wird Ihnen in der Menüleiste Ihres Webbrowsers
                  über die Hilfe-Funktion angezeigt, wie Sie neue Cookies abweisen und bereits erhaltene ausschalten
                  können. Wir empfehlen Ihnen, bei gemeinsam genutzten Computern, welche so eingestellt sind, dass sie
                  Cookies und Flash-Cookies akzeptieren, sich nach Beendigung stets vollständig abzumelden.</p>
                <br />
                <h4>3. Rechtsgrundlage für die Datenverarbeitung</h4>
                <p>Für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies stellt Art. 6 Abs. 1 lit.
                  f DS-GVO die notwendige Rechtsgrundlage dar.
                  <br /> Für die Verarbeitung personenbezogener Daten unter Verwendung von technisch notwendigen
                  Cookies stellt Art. 6 Abs. 1 lit. f DS-GVO die notwendige Rechtsgrundlage dar.
                  <br /> Für die Verarbeitung personenbezogener Daten zu Analysezecken unter Verwendung von technisch
                  nicht notwendigen Cookies stellt eine diesbezüglich vorliegende Einwilligung nach Art. 6 Abs. 1 lit.
                  a DS-GVO die notwendige Rechtsgrundlage dar.
                </p>
                <br />
                <h4>4. Zweck der Datenverarbeitung</h4>
                <p>Der Einsatz technisch notwendiger Cookies erfolgt zum Zwecke der Vereinfachung der Nutzung unserer
                  Websites für Sie. Ohne die Verwendung von Cookies können diverse Funktionen unserer Website nicht
                  angeboten werden, da für diese die Wiedererkennung des Browsers nach einem Seitenwechsel
                  erforderlich ist.
                  <br />
                  <br /> Folgende Anwendungen benötigen Cookies:
                  <br />
                  <br /> a) Merken von Suchbegriffen b) Übernahme von Spracheinstellungen
                  <br /> Es findet keine Erstellung von Nutzerprofilen mittels von technisch notwendigen Cookies
                  erhobener Nutzerdaten statt. Der Einsatz von Analyse-Cookies erfolgt zum Zwecke der
                  Qualitätsoptimierung unserer Website und deren Inhalte. Wir können unser Angebot regelmäßig
                  verbessern, indem wir Analyse-Cookies verwenden und so erfahren, wie unsere Website genutzt wird.
                  <br />
                  <br /> a) Marketing
                  <br /> Ebenso stellen diese Zwecke unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6
                  Abs. 1 lit. f DS-GVO dar.
                </p>
                <br />
                <h4>5. Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit</h4>
                <p>Die Speicherung von Cookies erfolgt auf Ihrem Rechner. Von dort werden sie an unsere Website
                  übermittelt. Über den Einsatz von Cookies haben somit Sie als Nutzer auch die volle Kontrolle. Sie
                  können durch eine Änderung der Einstellungen in Ihrem Internetbrowser die Übermittlung von Cookies
                  deaktivieren oder einschränken. Sie können auch bereits gespeicherte Cookies jederzeit löschen. Dies
                  kann auch automatisiert erfolgen. Deaktivieren Sie Cookies für unsere Website, können hierdurch
                  eventuell Nutzungseinschränkungen der Funktionen unserer Website entstehen.</p>
                <br />
                <h3>VI. Newsletter</h3>
                <h4>1. Beschreibung und Umfang der Datenverarbeitung </h4>
                <p>Wir bieten auf unserer Internetseite die Möglichkeit eines kostenlosen Newsletter-Abonnements an.
                  Die Daten aus der hierfür vorgesehenen Eingabemaske werden bei der Anmeldung zum Newsletter an uns
                  übermittelt.
                  <br /> a) Name
                  <br /> b) E-Mail-Adresse
                  <br /> c) Telefon
                  <br /> d) Nachricht
                  <br />
                  <br /> Bei der Anmeldung werden zudem folgende Daten erhoben:
                  <br /> a) Datum und Uhrzeit der Registrierung
                  <br /> b) IP-Adresse des aufrufenden Rechners
                  <br />
                  <br /> Während des Anmeldevorgangs holen wir Ihre Einwilligung zur Verarbeitung dieser Daten ein und
                  verweisen auf diese Datenschutzerklärung. Die Daten werden ausschließlich für den Newsletter-Versand
                  verwendet. Eine Weitergabe der für den Newsletter-Versand verarbeiteten Daten an Dritte findet nicht
                  statt.
                </p>
                <br />
                <h4>2. Rechtsgrundlage für die Datenverarbeitung</h4>
                <p>Für die Verarbeitung personenbezogener Daten nach Anmeldung zum Newsletter durch den Nutzer stellt
                  eine diesbezüglich vorliegende Einwilligung nach Art. 6 Abs. 1 lit. a DS-GVO die notwendige
                  Rechtsgrundlage dar.</p>
                <br />
                <h4>3. Zweck der Datenverarbeitung</h4>
                <p>Wir erheben Ihre E-Mail-Adresse, um den Newsletter zuzustellen. Zur Vorbeugung eines Missbrauchs
                  der Dienste oder der angegebenen E-Mail-Adresse, erheben wir sonstige personenbezogene Daten während
                  des Anmeldevorgangs.</p>
                <br />
                <h4>4. Dauer der Speicherung</h4>
                <p>Sofern die Datenerhebung zur Erreichung des Zwecks nicht mehr notwendig ist, werden die Daten
                  gelöscht. Somit speichern wir Ihre E-Mail-Adresse für die Dauer des aktiven Newsletter-Abonnements.
                  <br /> In der Regel werden die während des Anmeldevorgangs erhobenen, sonstigen personenbezogenen
                  Daten nach einer Frist von sieben Tagen gelöscht.
                </p>
                <br />
                <h4>5. Widerspruchs- und Beseitigungsmöglichkeit</h4>
                <p>Sie können das Newsletter-Abonnement jederzeit kündigen. Hierfür finden Sie in jedem Newsletter
                  einen entsprechenden Link.
                  <br /> Hiermit können Sie gleichfalls die Einwilligung zur Speicherung der während des
                  Anmeldevorgangs erhobenen, sonstigen personenbezogenen Daten widerrufen.
                </p>
                <br />
                <h3>VII. Kontaktformular und E-Mail-Kontakt</h3>
                <h4>1. Beschreibung und Umfang der Datenverarbeitung</h4>
                <p>Zur elektronischen Kontaktaufnahme bieten wir auf unserer Website ein Kontaktformular an. Zu dessen
                  Verwendung geben Sie Ihre Daten in der Eingabemaske ein. Anschließend werden diese Daten an uns
                  übermittelt und gespeichert. Folgende Daten werden erhoben:
                  <br />
                  <br /> a) Name
                  <br /> b) E-Mail-Adresse
                  <br /> c) Telefon
                  <br /> d) Nachricht
                  <br />
                  <br /> Im Zeitpunkt des Nachrichtenversands werden zudem folgende Daten gespeichert:
                  <br />
                  <br /> a) Datum und Uhrzeit der Registrierung
                  <br /> b) IP-Adresse des aufrufenden Rechners
                  <br />
                  <br /> Während des Absendevorgangs holen wir Ihre Einwilligung zur Verarbeitung dieser Daten ein und
                  verweisen auf diese Datenschutzerklärung.
                  <br /> Daneben können Sie über die bereitgestellte E-Mail-Adresse Kontakt zu uns aufnehmen. Dabei
                  werden Ihre mit der E-Mail übermittelten personenbezogenen Daten gespeichert.
                  <br /> Die Daten werden ausschließlich für die Verarbeitung der Kommunikation verwendet. Eine
                  Weitergabe der für die Kommunikation verarbeiteten Daten an Dritte findet nicht statt.
                </p>
                <br />
                <h4>2. Rechtsgrundlage für die Datenverarbeitung </h4>
                <p>Für die Verarbeitung der Daten stellt eine diesbezüglich vorliegende Einwilligung des Nutzers nach
                  Art. 6 Abs. 1 lit. a DS-GVO die notwendige Rechtsgrundlage dar.
                  <br /> Sofern die Übermittlung der personenbezogenen Daten über eine E-Mail erfolgt, stellt Art. 6
                  Abs. 1 lit. f DS-GVO für die Verarbeitung der Daten die notwendige Rechtsgrundlage dar. Ist mit
                  dieser Kontaktaufnahme auch der Abschluss eines Vertrages beabsichtigt, so stellt daneben Art. 6
                  Abs. 1 lit. b DS-GVO die notwendige Rechtsgrundlage für die Verarbeitung dar.
                </p>
                <br />
                <h4>3. Zweck der Datenverarbeitung </h4>
                <p>Wir verarbeiten die über die Eingabemaske erhobenen personenbezogenen Daten ausschließlich zur
                  Bearbeitung der Kontaktaufnahme. Sofern Sie per E-Mail Kontakt zu uns aufnehmen, ist hierin auch das
                  erforderliche berechtigte Interesse an der Verarbeitung der Daten zu sehen.
                  <br /> Zur Vorbeugung eines Missbrauchs des Kontaktformulars und zur Sicherung unserer IT-Systeme
                  nutzen uns die sonstigen während des Absendevorgangs verarbeiteten personenbezogenen Daten.
                </p>
                <br />
                <h4>4. Dauer der Speicherung</h4>
                <p>Sofern die Datenerhebung zur Erreichung des Zwecks nicht mehr notwendig ist, werden die Daten
                  gelöscht. Dies ist für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und
                  diejenigen, die per E-Mail übersandt wurden, gegeben, wenn die jeweilige Kommunikation mit Ihnen
                  beendet ist. Dies ist der Fall, wenn die Umstände erkennen lassen, dass eine Klärung des betroffenen
                  Sachverhalts abschließend erfolgte.
                  <br /> Spätestens nach einer Frist von sieben Tagen werden die während des Absendevorgangs
                  zusätzlich erhobenen, sonstigen personenbezogenen Daten gelöscht.
                </p>
                <br />
                <h4>5. Widerspruchs- und Beseitigungsmöglichkeit</h4>
                <p>Sie können die Einwilligung zur Verarbeitung der personenbezogenen Daten jederzeit widerrufen. Bei
                  einer Kontaktaufnahme per E-Mail können Sie der Speicherung Ihrer personenbezogenen Daten jederzeit
                  widersprechen. Bitte beachten Sie, dass für diesen Fall eine weitere Kommunikation mit Ihnen nicht
                  länger fortgeführt werden kann.
                  <br /> Zum Zwecke der Kontaktaufnahme gespeicherte personenbezogene Daten werden in diesem Fall
                  vollständig gelöscht.
                </p>
                <br />
                <h3>VIII. Weitergabe Ihrer Daten an Dritte</h3>
                <p>Damit wir unsere Website für Sie als Nutzer so angenehm und komfortabel wie möglich gestalten
                  können, setzen wir vereinzelt Dienste externer Dienstleister ein. Nachfolgend haben Sie die
                  Möglichkeit, sich über die Datenschutzbestimmungen zum Einsatz und Verwendung der eingesetzten
                  Dienste und Funktionen zu informieren, um eventuell auch bei diesen Dienstleistern Ihre Rechte
                  wahrzunehmen.
                  <br />
                  <br /> Google Analytics
                  <br /> Google Analytics Remarketing
                  <br /> Google AdWords
                  <br /> Google Maps
                  <br /> Social Media Plugins
                </p>
                <br />
                <h4>1. Google Analytics </h4>
                <p>Google Analytics ist eine Dienstleistung von Google Ireland Limited, Gordon House, Barrow Street,
                  Dublin 4, Irland ("Google"). Google Analytics verwendet "Cookies", also Textdateien, die auf Ihrem
                  Computer gespeichert werden und eine Analyse der Nutzung unseres Angebots durch Google ermöglicht.
                  Die durch den Cookie erfassten Informationen über die Benutzung unserer Webseiten (einschließlich
                  Ihrer IP-Adresse) werden in der Regel an einen Server von Google in die USA übertragen und dort
                  gespeichert.
                  <br />
                  <br /> Ihre IP-Adresse wird auf unsere Veranlassung hin von Google lediglich in gekürzter Form
                  erfasst, was eine Anonymisierung gewährleistet und keine Rückschlüsse auf Ihre Identität zulässt. Im
                  Falle der Aktivierung der IP- Anonymisierung auf unseren Webseiten, wird Ihre IP-Adresse von Google
                  innerhalb von Mitgliedsstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
                  über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse
                  an einen Server von Google in den USA übertragen und dort gekürzt.
                  <br />
                  <br /> Google wird die genannten Informationen verwenden, um Ihre Nutzung unserer Webseiten
                  auszuwerten, um Reports über die Webseitenaktivitäten für uns zusammenzustellen und um weitere mit
                  der Nutzung von Webseiten und Internet verbundene Dienstleistungen an uns zu erbringen. Die im
                  Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten
                  von Google zusammengeführt. Eine Übertragung dieser Daten durch Google an Dritte findet nur aufgrund
                  gesetzlicher Vorschriften oder im Rahmen der Auftragsverarbeitung statt. Keinesfalls wird Google
                  ihre Daten mit anderen von Google erfassten Daten zusammenbringen.
                  <br />
                  <br /> Durch die Nutzung dieser Webseiten erklären Sie sich mit der Bearbeitung der über Sie
                  erhobenen Daten durch Google und der zuvor beschriebenen Art und Weise der Datenverarbeitung sowie
                  dem benannten Zweck einverstanden. Sie können die Speicherung der Cookies durch eine entsprechende
                  Einstellung Ihrer Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem
                  Fall gegebenenfalls nicht sämtliche Funktionen unserer Webseiten vollumfänglich werden nutzen
                  können.
                  <br />
                  <br /> Nähere Informationen zu Google Analytics und Datenschutz finden Sie unter
                  <a href="https://tools.google.com/dlpage/gaoptout" rel="noreferrer"
                     target="_blank">https://tools.google.com/dlpage/gaoptout</a>.
                  <br />
                  <br />
                </p>
                <h4>2. Google Analytics Remarketing</h4>
                <p>
                  Unsere Websites nutzen die Funktionen von Google Analytics Remarketing in Verbindung mit den
                  geräteübergreifenden Funktionen von Google AdWords und Google DoubleClick. Anbieter ist die Google
                  Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland ("Google"). Diese Funktion
                  ermöglicht es die mit Google Analytics Remarketing erstellten Werbe-Zielgruppen mit den
                  geräteübergreifenden Funktionen von Google AdWords und Google DoubleClick zu verknüpfen. Auf diese
                  Weise können interessenbezogene, personalisierte Werbebotschaften, die in Abhängigkeit Ihres
                  früheren Nutzungs- und Surfverhaltens auf einem Endgerät (z.B. Handy) an Sie angepasst wurden auch
                  auf einem anderen Ihrer Endgeräte (z.B. Tablet oder PC) angezeigt werden.
                  <br />
                  <br /> Haben Sie eine entsprechende Einwilligung erteilt, verknüpft Google zu diesem Zweck Ihren
                  Web- und App-Browserverlauf mit Ihrem Google-Konto. Auf diese Weise können auf jedem Endgerät auf
                  dem Sie sich mit Ihrem Google-Konto anmelden, dieselben personalisierten Werbebotschaften
                  geschaltet werden.
                  <br />
                  <br /> Zur Unterstützung dieser Funktion erfasst Google Analytics google-authentifizierte IDs der
                  Nutzer, die vorübergehend mit unseren Google-Analytics-Daten verknüpft werden, um Zielgruppen für
                  die geräteübergreifende Anzeigenwerbung zu definieren und zu erstellen.
                  <br />
                  <br /> Sie können dem geräteübergreifenden Remarketing/Targeting dauerhaft widersprechen, indem
                  Sie personalisierte Werbung in Ihrem Google-Konto deaktivieren; folgen Sie hierzu diesem Link:
                  <a href="https://www.google.com/settings/ads/onweb" rel="noreferrer"
                     target="_blank">https://www.google.com/settings/ads/onweb</a>.
                  <br />
                  <br /> Die Zusammenfassung der erfassten Daten in Ihrem Google-Konto erfolgt ausschließlich auf
                  Grundlage Ihrer Einwilligung, die Sie bei Google abgeben oder widerrufen können (Art. 6 Abs. 1
                  lit. a DSGVO). Bei Datenerfassungsvorgängen, die nicht in Ihrem Google-Konto zusammengeführt
                  werden (z.B. weil Sie kein Google-Konto haben oder der Zusammenführung widersprochen haben) beruht
                  die Erfassung der Daten auf Art. 6 Abs. 1 lit. f DSGVO. Das berechtigte Interesse ergibt sich
                  daraus, dass der Websitebetreiber ein Interesse an der anonymisierten Analyse der Websitebesucher
                  zu Werbezwecken hat.
                  <br />
                  <br /> Weitergehende Informationen und die Datenschutzbestimmungen finden Sie in der
                  Datenschutzerklärung von Google unter:
                  <a href="https://www.google.com/policies/technologies/ads" rel="noreferrer"
                     target="_blank">https://www.google.com/policies/technologies/ads.</a>
                  <br />
                  <br />
                </p>
                <h4>3. Google AdWords und Google Conversion-Tracking</h4>
                <p>Diese Website verwendet Google AdWords. AdWords ist ein Online-Werbeprogramm von Google Ireland
                  Limited, Gordon House, Barrow Street, Dublin 4, Irland ("Google").
                  <br />
                  <br /> Im Rahmen von Google AdWords nutzen wir das so genannte Conversion-Tracking. Wenn Sie auf
                  eine von Google geschaltete Anzeige klicken wird ein Cookie für das Conversion-Tracking gesetzt.
                  Bei Cookies handelt es sich um kleine Textdateien, die der Internet-Browser auf dem Computer des
                  Nutzers ablegt. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der
                  persönlichen Identifizierung der Nutzer. Besucht der Nutzer bestimmte Seiten dieser Website und
                  das Cookie ist noch nicht abgelaufen, können Google und wir erkennen, dass der Nutzer auf die
                  Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.
                  <br />
                  <br /> Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies können nicht über die
                  Websites von AdWords-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten
                  Informationen dienen dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für
                  Conversion-Tracking entschieden haben. Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf
                  ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite
                  weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich
                  identifizieren lassen. Wenn Sie nicht am Tracking teilnehmen möchten, können Sie dieser Nutzung
                  widersprechen, indem Sie das Cookie des Google Conversion-Trackings über ihren Internet-Browser
                  unter Nutzereinstellungen leicht deaktivieren. Sie werden sodann nicht in die
                  Conversion-Tracking Statistiken aufgenommen.
                  <br />
                  <br /> Die Speicherung von “Conversion-Cookies” erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
                  DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens,
                  um sowohl sein Webangebot als auch seine Werbung zu optimieren.
                  <br />
                  <br /> Mehr Informationen zu Google AdWords und Google Conversion-Tracking finden Sie in den
                  Datenschutzbestimmungen von Google:
                  <a href="https://www.google.de/policies/privacy" rel="noreferrer"
                     target="_blank">https://www.google.com/policies/technologies/ads</a>.
                  <br />
                  <br /> Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert
                  werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder
                  generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers
                  aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website
                  eingeschränkt sein.
                </p>
                <br />
                <br />
                <h4>4. Google Maps </h4>
                <p> Unsere Webseiten nutzen Google Maps zur Darstellung von Karten und zur Erstellung von
                  Anfahrtsplänen. Google Maps wird von der Google Ireland Limited, Gordon House, Barrow Street,
                  Dublin 4, Irland betrieben. Durch die Nutzung dieser Website erklären Sie sich mit der
                  Erfassung, Bearbeitung sowie der Nutzung der automatisch erhobenen sowie der von Ihnen
                  eingegeben Daten durch Google, einer seiner Vertreter oder Drittanbieter einverstanden. Die
                  Nutzungsbedingungen für Google Maps finden Sie unter
                  <a href="http://www.google.com/intl/de_de/help/terms_maps.html" rel="noreferrer"
                     target="_blank">Nutzungsbedingungen</a> für Google Maps.
                  <br /> Ausführliche Details finden Sie im Datenschutz-Center von google.de:
                  <a href="https://policies.google.com/privacy?hl=de&gl=de" rel="noreferrer" target="_blank">Transparenz
                    und
                    Wahlmöglichkeiten</a> sowie den
                  <a href="https://policies.google.com/privacy?hl=de&gl=de" rel="noreferrer"
                     target="_blank">Datenschutzbestimmungen</a>.
                </p>
                <br />
                <h4>5. Google Drive und Google Forms </h4>
                <p> Zur Bereitstellung bestimmter Leistungen für Interessenten und Bestandskunden verwenden wir
                  Google Forms, welches von der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
                  Irland betrieben wird. Die mit einem Google Forms Formular erhobenen Daten werden auf einem von
                  Google für uns bereitgestellten Cloud Speicher, „Google Drive“, gespeichert.
                  <br /> Google LLC als Muttergesellschaft hat sich dem zwischen der Europäischen Union und den
                  USA geschlossenen Privacy-Shield-Abkommen unterworfen und sich zertifiziert. Dadurch
                  verpflichtet sich Google, die Standards und Vorschriften des europäischen Datenschutzrechts
                  einzuhalten. Nähere Informationen können Sie diesem verlinkten
                  <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                     rel="noreferrer" target="_blank">Eintrag</a> entnehmen. Nähere Informationen über die
                  Datenverarbeitung im
                  Zusammenhang mit Google Forms und Google Drive können Sie den&nbsp;
                  <a href="https://www.google.com/intl/de/policies/privacy/" rel="noreferrer"
                     target="_blank">Datenschutzhinweisen</a> von Google entnehmen.
                  <br />
                </p>
                <h3>IX. Rechte der betroffenen Person</h3>
                <p> Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DS-GVO.
                  Damit stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:</p>

                <h4>1. Auskunftsrecht </h4>
                <p>
                  Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob Sie betreffende
                  personenbezogene Daten von uns verarbeitet werden.
                  <br /> Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende
                  Informationen Auskunft verlangen:
                  <br />
                  <br /> a) die Verarbeitungszwecke;
                  <br /> b) die Kategorien von personenbezogenen Daten, die verarbeitet werden;
                  <br /> c) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie
                  betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;
                  <br /> d) falls möglich die geplante Dauer, für die Ihre personenbezogenen Daten gespeichert
                  werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer;
                  <br /> e) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden
                  personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen
                  oder eines Widerspruchsrechts gegen diese Verarbeitung;
                  <br /> f) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
                  <br /> g) wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden,
                  alle verfügbaren Informationen über die Herkunft der Daten;
                  <br /> h) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling
                  gemäß Art. 22 Abs. 1 und 4 DS-GVO und - zumindest in diesen Fällen - aussagekräftige
                  Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen
                  einer derartigen Verarbeitung für die betroffene Person.
                  <br />
                  <br /> Ebenso können Sie Auskunft darüber verlangen, ob eine Übermittlung der Sie betreffenden
                  personenbezogenen Daten in ein Drittland oder an eine internationale Organisation stattfindet.
                  Ist dies der Fall, so können Sie die Unterrichtung, über die im Zusammenhang mit der
                  Übermittlung stehenden, geeigneten Garantien gemäß Art. 46 DS-GVO, verlangen.
                </p>
                <br />
                <br />
                <h4>2. Recht auf Berichtigung </h4>
                <p>
                  Sie können von dem Verantwortlichen die Berichtigung der Sie betreffenden unrichtigen
                  personenbezogenen Daten verlangen. Ebenso können Sie unter Berücksichtigung der Zwecke der
                  Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten - auch mittels
                  einer ergänzenden Erklärung - verlangen. Der Verantwortliche hat die begehrte Berichtigung
                  unverzüglich vorzunehmen.
                </p>
                <br />
                <br />
                <h4>3. Recht auf Löschung („Recht auf Vergessenwerden“)</h4>
                <p>
                  Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen
                  Daten unverzüglich gelöscht werden und der Verantwortliche ist verpflichtet, diese Daten
                  unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:.
                  <br />
                  <br /> a) Ihre personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf
                  sonstige Weise verarbeitet wurden, nicht mehr notwendig.
                  <br /> b) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1
                  lit. a oder Art. 9 Abs. 2 lit. a DS-GVO stützte und es fehlt an einer anderweitigen
                  Rechtsgrundlage für die Verarbeitung.
                  <br /> c) Sie legen gemäß Art. 21 Abs. 1 DS-GVO Widerspruch gegen die Verarbeitung ein und es
                  liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor oder Sie legen gem. Art.
                  21 Abs. 2 DS-GVO Widerspruch gegen die Verarbeitung ein.
                  <br /> d) Ihre personenbezogenen Daten wurden unrechtmäßig verarbeitet.
                  <br /> e) Die Löschung Ihrer personenbezogenen Daten ist zur Erfüllung einer rechtlichen
                  Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der
                  Verantwortliche unterliegt.
                  <br /> f) Ihre personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
                  Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.
                  <br />
                  <br /> Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht
                  und ist er gemäß Art. 17 Abs. 1 DS-GVO zu deren Löschung verpflichtet, so trifft er unter
                  Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene
                  Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die
                  personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person
                  von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder
                  Replikationen dieser personenbezogenen Daten verlangt haben.
                  <br />
                  <br /> Das Recht auf Löschung besteht aber nicht, sofern die Verarbeitung nachfolgend
                  erforderlich ist.
                  <br /> a) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
                  <br /> b) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht
                  der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert oder zur
                  Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher
                  Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
                  <br /> c) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß
                  Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DS-GVO;
                  <br /> d) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder
                  historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DS-GVO, soweit
                  das oben beschriebene Recht zur Löschung voraussichtlich die Verwirklichung der Ziele dieser
                  Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt oder
                  <br /> e) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                </p>
                <br />
                <h4>4. Recht auf Einschränkung der Verarbeitung </h4>
                <p>
                  Sie können von dem Verantwortlichen die Einschränkung der Verarbeitung Ihrer
                  personenbezogenen Daten verlangen, wenn eine der folgenden Voraussetzungen vorliegt:
                  <br />
                  <br /> a) die Richtigkeit Ihrer personenbezogenen wurde von Ihnen bestritten, und zwar für
                  eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit Ihrer personenbezogenen
                  Daten zu überprüfen;
                  <br /> b) die Verarbeitung ist unrechtmäßig und Sie lehnen die Löschung der personenbezogenen
                  Daten ab und verlangen stattdessen die Einschränkung der Nutzung Ihrer personenbezogenen
                  Daten;
                  <br /> c) der Verantwortliche benötigt Ihre personenbezogenen Daten für die Zwecke der
                  Verarbeitung nicht länger, aber Sie benötigen diese jedoch zur Geltendmachung, Ausübung oder
                  Verteidigung von Rechtsansprüchen, oder
                  <br /> d) Sie haben Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DS-GVO eingelegt
                  und es steht noch nicht fest, ob die berechtigten Gründe des Verantwortlichen gegenüber den
                  Ihrigen überwiegen.
                  <br />
                  <br /> Wurde die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt, so dürfen diese
                  personenbezogenen Daten - von ihrer Speicherung abgesehen - nur mit Ihrer Einwilligung oder
                  zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte
                  einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen
                  öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
                  <br />
                  <br /> Sofern Sie eine Einschränkung der Verarbeitung nach den oben genannten Voraussetzungen
                  erwirkt haben, so werden Sie von dem Verantwortlichen unterrichtet, bevor die Einschränkung
                  aufgehoben wird.
                </p>
                <br />
                <br />
                <h4>5. Recht auf Unterrichtung </h4>
                <p>
                  Sofern Sie die zuvor beschriebenen Rechte auf Berichtigung, Löschung oder Einschränkung der
                  Verarbeitung gegenüber dem Verantwortlichen geltend gemacht haben, so hat dieser allen
                  Empfängern, denen Ihre personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
                  Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist
                  sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.
                  <br />
                  <br /> Auf Ihr Verlangen hat der Verantwortliche Ihnen diese Empfänger mitzuteilen.
                </p>
                <br />
                <br />
                <h4>6. Recht auf Datenübertragbarkeit</h4>
                <p>
                  Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem
                  Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren
                  Format zu erhalten. Ebenso haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne
                  Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden,
                  zu übermitteln, sofern
                  <br />
                  <br /> a) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DS-GVO oder Art. 9
                  Abs. 2 lit. a DS-GVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DS-GVO beruht und
                  <br /> b) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
                  <br />
                  <br /> Bei der Ausübung dieses Rechts haben Sie ebenso das Recht zu erwirken, dass Ihre
                  personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen
                  übermittelt werden, soweit dies technisch machbar ist.
                  <br />
                  <br /> Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener
                  Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse
                  liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
                  <br />
                  <br /> Ebenso dürfen durch das Recht auf Datenübertragbarkeit Rechte und Freiheiten anderer
                  Personen nicht beeinträchtigt werden.
                </p>
                <br />
                <h4>7. Widerspruchsrecht</h4>
                <p>
                  Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit
                  gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6
                  Abs. 1 lit. e oder f DS-GVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
                  Bestimmungen gestütztes Profiling.
                  <br />
                  <br /> Der Verantwortliche verarbeitet Ihre personenbezogenen Daten nicht mehr, es sei denn,
                  er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
                  Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder
                  Verteidigung von Rechtsansprüchen.
                  <br />
                  <br /> Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben
                  Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden
                  personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das
                  Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
                  <br />
                  <br /> Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden Ihre
                  personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
                  <br />
                  <br /> Im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft können Sie
                  ungeachtet der Richtlinie 2002/58/EG Ihr Widerspruchsrecht mittels automatisierter Verfahren
                  ausüben, bei denen technische Spezifikationen verwendet werden.
                </p>
                <br />
                <br />
                <h4>8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h4>
                <p>
                  Sie können Ihre datenschutzrechtliche Einwilligungserklärung jederzeit widerrufen. Durch den
                  Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum
                  Widerruf erfolgten Verarbeitung nicht berührt. </p>
                <br />
                <br />
                <h4>9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling </h4>
                <p>
                  Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung -
                  einschließlich Profiling - beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber
                  rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.
                  <br />
                  <br /> Dies gilt nicht, wenn die Entscheidung
                  <br />
                  <br /> a) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem
                  Verantwortlichen erforderlich ist,
                  <br /> b) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der
                  Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen
                  zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
                  <br /> c) mit Ihrer ausdrücklichen Einwilligung erfolgt.
                  <br />
                  <br /> In den unter a) und c) genannten Fälle trifft der Verantwortliche angemessene
                  Maßnahmen, um Ihre Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu
                  mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen,
                  auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
                  <br />
                  <br /> Die Entscheidungen nach a) bis c) dürfen nicht auf besonderen Kategorien
                  personenbezogener Daten nach Art. 9 Abs. 1 DS-GVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a
                  oder g DS-GVO gilt und angemessene Maßnahmen zum Schutz Ihrer Rechte und Freiheiten sowie
                  Ihrer berechtigten Interessen getroffen wurden.
                </p>
                <br />
                <h4>10. Recht auf Beschwerde bei einer Aufsichtsbehörde</h4>
                <p>
                  Sie haben unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
                  Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem
                  Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
                  mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
                  personenbezogenen Daten gegen die DS-GVO verstößt.
                  <br />
                  <br /> Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den
                  Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der
                  Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DS-GVO.
                </p>
                <br />
                <h3>X. Aktualität und Änderung dieser Datenschutzerklärung</h3>
                <p>
                  Die Weiterentwicklung unserer Website und deren Angebote oder geänderte gesetzliche
                  Bestimmungen, beziehungsweise ergangene Rechtsprechung, beziehungsweise behördliche Vorgaben,
                  können es erforderlich machen, diese Datenschutzerklärung zu ändern.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-12">
            <div className={"d-flex flex-column"}>
              <aside className="widget-area">
                <div className="widget widget_insight">
                  <ul>
                    <li>
                      <Link to="/impressum/">
                        Impressum
                      </Link>
                    </li>
                    <li>
                      <Link to="/agb/">
                        AGB
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/datenschutz/">
                        Datenschutz
                      </Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default DatenschutzContent
